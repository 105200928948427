import { Box, Typography, useTheme } from '@mui/material';
import convertEnumToDisplayString from 'apps/webapp/src/@core/utils/convert-enum-to-display-string';

const CustomLegend = ({
  data,
  isVertical = false,
}: {
  data: { text: string; value: string | number; color?: string }[];
  isVertical?: boolean;
}) => {
  const theme = useTheme();

  const getColor = (index: number) => {
    switch (index) {
      case 0:
        return '#6461F3';
      case 1:
        return '#C0AAFF';
      case 2:
        return '#263238';
      case 3:
        return '#CFD8DC';
      default:
        return '#6461F3';
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'flex-start', flexDirection: isVertical ? 'column' : 'row' }}>
      {data.map((item, index) => {
        return (
          <Box key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
              <Box
                sx={{ width: '17px', height: '17px', borderRadius: '3px', background: item?.color ?? getColor(index) }}
              ></Box>
              <Typography sx={{ color: '6F6F6F', fontSize: '12px' }}>
                {convertEnumToDisplayString(item.text)}
              </Typography>
            </Box>
            <Typography
              sx={{ color: theme.palette.customColors.headingPrimary, fontSize: '24px', fontWeight: 700, pt: '10px' }}
            >
              {item.value}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default CustomLegend;
