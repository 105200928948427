import ReactApexcharts from 'apps/webapp/src/@core/components/react-apexcharts';
import React from 'react';

interface DashSpeedometer {
  colors?: string[];
  displayData: {
    label: string;
    value: number;
  }[];
  setSelectedLabel?: (label: any) => void;
}

const DashSpeedometer = ({
  displayData,
  colors = ['#E6E5FA', '#B8B7FF', '#6461F3'],
  setSelectedLabel,
}: DashSpeedometer) => {
  return (
    <ReactApexcharts
      align='center'
      type='donut'
      options={{
        chart: {
          type: 'donut',
          events: {
            dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex }) => {
              const selectedLabel = displayData[dataPointIndex].label;
              setSelectedLabel && setSelectedLabel(selectedLabel);
            },
          },
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
          },
        },
        grid: {
          padding: {
            bottom: -100,
          },
        },
        legend: {
          show: false,
          markers: {
            fillColors: colors,
          },
        },
        fill: {
          type: 'solid',
          colors: colors,
        },
        tooltip: {
          fillSeriesColor: true,
        },
        dataLabels: {
          enabled: false,
        },
        labels: displayData.map(data => data.label),
        colors: colors,
      }}
      series={displayData.map(data => data.value)}
    />
  );
};

export default DashSpeedometer;
