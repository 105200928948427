import { Box, Typography, useTheme } from '@mui/material';

const ChangeChip = ({ old, now }: { old: number; now: number }) => {
  const theme = useTheme();

  const change =
    old !== 0 ? (
      parseFloat((((now - old) / old) * 100).toFixed(2))
    ) : now > 0 ? (
      <Typography sx={{ fontSize: '1rem', color: theme.palette.status.successTextShade, mt: -0.5 }}>∞</Typography>
    ) : (
      0
    );
  const isPositive = (typeof change === 'number' && change >= 0) || typeof change !== 'number';

  return (
    <Box
      sx={{
        width: '42px',
        minWidth: 'fit-content',
        height: '13px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '2px',
        background: isPositive ? theme.palette.status.success : '#FFE0B8',
        color: isPositive ? theme.palette.status.successTextShade : '#D28B00',
        fontSize: '8px',
        fontWeight: '600',
      }}
    >
      {isPositive ? '+' : ''}
      {change} {typeof change === 'number' ? '%' : ''}
    </Box>
  );
};

export default ChangeChip;
